<script lang="ts">
	import Checkbox from '$lib/components/ui/Checkbox.svelte';
	import { slide } from 'svelte/transition';
	interface Props {
		name: string;
		label_for: string;
		error: string[] | undefined;
		checked?: boolean;
		children?: import('svelte').Snippet;
	}

	let {
		name,
		label_for,
		error = $bindable(undefined),
		checked = $bindable(),
		children
	}: Props = $props();
</script>

<div class="input-container">
	<Checkbox required id={label_for} {name} bind:checked>
		<label for={label_for} class="text-sm text-white">
			{@render children?.()}
		</label>
	</Checkbox>
	<div>
		{#if error}
			<div
				class="validation-message"
				id={`${name}-error`}
				role="alert"
				transition:slide|local={{ duration: 300, axis: 'y' }}
			>
				<ul class="validation-message-ul">
					{#each error as message (message)}
						<li transition:slide|local={{ duration: 300, axis: 'y' }}>
							{message}
						</li>
					{/each}
				</ul>
			</div>
		{/if}
	</div>
</div>

<style>
	.input-container {
		/* position: relative; */
		width: 100%;
	}

	.validation-message {
		display: block;
		width: 100%;
		padding: 0.3rem;
		/* box-sizing: border-box; */
		margin-top: -6px;
		z-index: 0;
	}

	.validation-message-ul {
		margin: 0;
		padding: 0;
		padding-top: 0.25rem;
		list-style: none;
		color: #f07575;
	}

	.validation-message-ul li {
		margin-bottom: 0.25rem;
	}

	.validation-message-ul li:last-child {
		margin-bottom: 0;
	}
</style>
